// 此文件专门负责项目的路由


import VueRouter from 'vue-router';
// 引入组件
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Login from '../views/login/Login'
import Register from '../views/register/Register'
import Home from '../views/home/Home'
import { Message } from "element-ui";

import ProductInfo from '../views/components/ProductInfo';
import MerchantAddress from '../views/components/MerchantAddress';
import CustomerServiceQR from '../views/components/CustomerServiceQR';
import CameraView from '../views/components/CameraView';
import ProductGallery from '../views/components/ProductGallery';
import UserInfo from '../views/components/UserInfo';
import QRecodes  from "../views/components/QRecodes";
import ProductInfo2 from "@/views/components/ProductInfo2.vue";


Vue.use(ElementUI);

    // 创建并暴露一个路由器
    const router = new VueRouter({
        mode: 'history',    // 路由模式，该模式不会在地址中显示井号#
        routes: [
    {
        path: '/',
        redirect: '/login'

    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/register',
        component: Register
    },
    {
        path: '/home',
        component: Home,
        redirect: '/product-info',
        children: [
            {
                path: '/product-info',
                component: ProductInfo
            },
            {
                path: '/merchant-address',
                component: MerchantAddress
            },
            {
                path: '/customer-qrcode',
                component: CustomerServiceQR
            },
            {
                path: '/camera',
                component: CameraView
            },
            {
                path: '/product-gallery',
                component: ProductGallery
            },
            {
                path: '/user-info',
                component: UserInfo
            },
            {
                path: '/q-recodes',
                component: QRecodes
            },{
                path: '/product-info2',
                component: ProductInfo2
            },

    ]

    },
]
    })

// 导航守卫，前置处理
router.beforeEach((to, from, next) => {
    let isAuthenticated = !!sessionStorage.getItem('userInfo')
    // 如果路由要跳转到除了登录和注册的界面的话就判断是否已经登录，如果没有登录就强制跳到登录界面
    if (to.path !== '/login' && to.path !== '/register' && !isAuthenticated) {
        next({ path: '/login' })
        Message({
            message: '请先登录！',
            type: "warning",
        });
    } else next()
})

export default router;