<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      path: "",
    };
  },
  mounted() {
    this.path = this.$route.path;
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
    },
  },
  methods: {
    returnBtn() {
      if (window.history.length <= 1) {
        this.$router.push({
          path: "/home",
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
}
div {
  box-sizing: border-box;
}
</style>
