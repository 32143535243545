import { render, staticRenderFns } from "./ProductInfo.vue?vue&type=template&id=0d4c5168&scoped=true"
import script from "./ProductInfo.vue?vue&type=script&lang=js"
export * from "./ProductInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d4c5168",
  null
  
)

export default component.exports