<template>
  <div class="products" style="padding: 20px;">
    <!-- 搜索框 -->
    <el-form :inline="true" class="demo-form-inline">

      <el-form-item>
        <el-button type="primary" @click="openNewProductDialog = true">新增</el-button>

      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="productId" label="产品ID" ></el-table-column>
      <el-table-column prop="productCode" label="产品编号" ></el-table-column>
      <el-table-column prop="productName" label="产品名称" ></el-table-column>
      <el-table-column prop="nameIntroduction" label="产品介绍"></el-table-column>
<!--      <el-table-column prop="baseLiquorYear" label="基酒年份"></el-table-column>-->
      <el-table-column prop="manufacturer" label="生产厂家"></el-table-column>
      <el-table-column prop="alcoholContent" label="酒精度（°）"></el-table-column>
      <el-table-column prop="netContent" label="净含量（ml）"></el-table-column>
      <el-table-column prop="productionBatch" label="生产批次（年份）"></el-table-column>
      <el-table-column prop="productionQuantity" label="生产数量（瓶）"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间" ></el-table-column>

      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="confirmDeleteProduct(scope.row.productCode)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>



    <el-dialog :visible.sync="openNewProductDialog" title="新增产品"  >
      <el-form :inline="true" :model="newProduct">
          <el-form-item label="产品编号" >
            <el-input v-model="newProduct.productCode" placeholder="请输入产品编号（不能重复）"></el-input>
          </el-form-item>
          <el-form-item label="产品名称">
            <el-input v-model="newProduct.productName" placeholder="请输入产品名称"></el-input>
          </el-form-item>
          <el-form-item label="产品介绍">
            <el-input v-model="newProduct.nameIntroduction" placeholder="请输入产品介绍"></el-input>
          </el-form-item>
<!--          <el-form-item label="基酒年份">
            <el-input v-model="newProduct.baseLiquorYear" placeholder="请输入基酒年份（数字）"></el-input>
          </el-form-item>-->
          <el-form-item label="生产批次">
            <el-input v-model="newProduct.productionBatch" placeholder="请输入生产批次（年份）"></el-input>
          </el-form-item>
          <el-form-item label="生产数量">
            <el-input v-model="newProduct.productionQuantity" placeholder="请输入生产数量（数字）"></el-input>
          </el-form-item>
          <el-form-item label="生产厂家">
            <el-input v-model="newProduct.manufacturer" placeholder="请输入生产厂家"></el-input>
          </el-form-item>
          <el-form-item label="酒精度">
            <el-input v-model="newProduct.alcoholContent" placeholder="请输入酒精度"></el-input>
          </el-form-item>
          <el-form-item label="净含量">
            <el-input v-model="newProduct.netContent" placeholder="请输入净含量"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
        <el-button @click="openNewProductDialog = false">取消</el-button>
        <el-button type="primary" @click="addNewProduct">提交</el-button>
      </div>
    </el-dialog>

  </div>
</template>





<script>
import axios from 'axios';
import {Message, MessageBox} from "element-ui";

export default {
  name: "ProductInfo",
  data() {
    return {
      productName: '',
      tableData: [],
      openNewProductDialog: false,
      newProduct: {
        productCode: "",
        productName: "",
        nameIntroduction: "",
        baseLiquorYear: "",
        productionBatch: "",
        productionQuantity: "",
        manufacturer: "",
        alcoholContent: "",
        netContent: ""
      }
    }
  },
  methods: {
    query() {
      axios.get('api/products/')
          .then(response => {
            this.tableData = response.data;
          })
          .catch(error => {
            console.error(error);
          })
    },
    confirmDeleteProduct(productCode) {
      MessageBox.confirm('确定要删除此条商品信息吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteProduct(productCode);
      }).catch(() => {
        Message.info('取消操作');
      });
    },
    deleteProduct(productCode) {
      axios.delete(`api/products/${productCode}`)
          .then(() => {
            this.tableData = this.tableData.filter(product => product.productCode !== productCode);
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '删除失败'
            });
          });
    },
    addNewProduct() {
      axios.post('api/products/', this.newProduct)
          .then(response => {
            this.tableData.push(response.data);
            this.openNewProductDialog = false; // 关闭对话框
            this.$message({
              type: 'success',
              message: '产品添加成功!'
            });
            this.openNewCustomerServiceDialog = false;
            // 适当的逻辑来更新列表显示新添加的数据
            this.query(); // 重新加载所有数据
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '产品添加失败'
            });
          });
    },




  },
  created() {
    this.query();
  }
}
</script>




<style scoped>

</style>