export function useDataFormData(obj, fileList) {
  const formData = new FormData();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      formData.append("newMedia", JSON.stringify(obj)); // 将对象转换成JSON字符串
      break; // 因为我们需要整个对象作为一个JSON字符串发送，所以这里只需要一次append
    }
  }
  fileList.forEach((file) => {
    formData.append("fileList", file.raw);
  });
  return formData;
}