<template>
  <div class="q-recodes" style="padding: 20px;">
    <!-- 搜索框 -->
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-input v-model="productId" placeholder="请输入产品ID进行搜索"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" stripe style="width: 100%">
<!--      <el-table-column prop="qrcodeId" label="二维码ID"></el-table-column>-->
      <el-table-column prop="productId" label="所属产品ID"></el-table-column>
      <el-table-column prop="sequence" label="序号"></el-table-column>
      <el-table-column prop="qrCodeUrl" label="二维码地址"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="confirmDeleteProduct7(scope.row.productId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios';
import { Message, MessageBox } from "element-ui";

export default {
  name: "QRecodes",
  data() {
    return {
      productId: '', // 绑定到输入框的模型
      tableData: [],
      newQRecode: {
        qrcodeId: "",
        productId: "",
        sequence: "",
        qrCodeUrl: ""
      }
    };
  },
  methods: {
    query() {
      axios.get('api/generate_qrcode/')
          .then(response => {
            this.tableData = response.data;
          })
          .catch(error => {
            console.error(error);
          });
    },
    searchData() {
      if (!this.productId) {
        alert('请输入产品ID');
        return; // 如果产品ID为空，中断函数执行
      }
      axios.get(`api/generate_qrcode/product/${this.productId}`)
          .then(response => {
            // 假设响应返回一个或多个对象的数组
            this.tableData = response.data;
          })
          .catch(error => {
            console.error('请求失败:', error);
          });
    },
    confirmDeleteProduct7(productId) {
      MessageBox.confirm(
          '<p>确定要删除该商品绑定的所有二维码吗?</p><p style="color: red;">此操作会删除该商品绑定的所有二维码，一旦删除不可恢复！此批二维码将作废！</p>',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        this.promptPassword(productId);
      }).catch(() => {
        Message.info('取消操作');
      });
    },
    promptPassword(productId) {
      MessageBox.prompt('请输入您的登录密码进行安全验证', '验证', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'password'
      }).then(({ value }) => {
        this.validatePassword(value, productId);
      }).catch(() => {
        Message.info('取消操作');
      });
    },
    validatePassword(inputPassword, productId) {
      const storedPassword = sessionStorage.getItem("password");
      if (inputPassword === storedPassword) {
        this.deleteQRecode(productId);
      } else {
        this.$message({
          type: 'error',
          message: '密码验证失败'
        });
      }
    },
    deleteQRecode(productId) {
      axios.delete(`api/generate_qrcode/delete/${productId}`)
          .then(() => {
            // 删除成功后，从表格数据中移除这一行
            this.tableData = this.tableData.filter(item => item.productId !== productId);
            alert('删除成功');
          })
          .catch(error => {
            console.error('删除失败:', error);
            alert('删除失败，请检查服务器状态或网络连接。');
          });
    }
  },
  created() {
    this.query();
  }
}
</script>

<style scoped>
</style>
