<template>
  <div class="stock-displays" style="padding: 20px;">
    <!-- 搜索框 -->
    <el-form :inline="true" class="demo-form-inline">

      <el-form-item>

        <el-button type="primary" @click="openNewStockDisplayDialog = true">新增摄像头地址</el-button>
      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
<!--      <el-table-column prop="stockWineId" label="摄像头ID"  ></el-table-column>-->
      <el-table-column prop="productCode" label="所属产品编号"  ></el-table-column>
      <el-table-column prop="stockWineName" label="生产流程" ></el-table-column>
      <el-table-column prop="stockWineImageUrl" label="图片URL" >
        <template v-slot:default="scope">
          <el-image style="width: 100px; height: 60px" :src="scope.row.stockWineImageUrl" fit="cover"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="stockWineLiveUrl" label="直播URL" >

      </el-table-column>
      <el-table-column prop="stockWineDescription" label="生产流程详细信息描述" width="300"></el-table-column>
      <el-table-column fixed="right" label="操作"  >
        <template v-slot:default="scope">
          <el-button size="mini" type="danger" @click="confirmDeleteProduct5(scope.row.stockWineId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        :visible.sync="openNewStockDisplayDialog"
        title="新增摄像头信息"
        width="50%">
      <el-form :inline="true" :model="newStockDisplay">
        <el-form-item label="产品编号">
          <el-input v-model="newStockDisplay.productCode" placeholder="请输入产品编号(必须已存在的产品编号)"></el-input>
        </el-form-item>
        <el-form-item label="生产流程">
          <el-input v-model="newStockDisplay.stockWineName" placeholder="请输入生产流程"></el-input>
        </el-form-item>
        <el-form-item label="图片 URL">
          <FileUpload :multiple="false" @exportFileList="exportFileList" />
        </el-form-item>
        <el-form-item label="直播 URL">
          <el-input v-model="newStockDisplay.stockWineLiveUrl" placeholder="请输入直播URL地址"></el-input>
        </el-form-item>
        <el-form-item label="生产流程详细信息描述">
          <el-input
              type="textarea"
              v-model="newStockDisplay.stockWineDescription"
              placeholder="请输入描述"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openNewStockDisplayDialog = false">取消</el-button>
        <el-button type="primary" @click="addStockDisplay">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import {Message, MessageBox} from "element-ui";
import {useDataFormData} from "@/utils/useDataFormData";
import FileUpload from "@/views/components/FileUpload.vue";

export default {
  name: "CameraView",
  components: {FileUpload},
  data() {
    return {
      productCode: '',
      tableData: [],
      openNewStockDisplayDialog: false,
      newStockDisplay: {
        productCode: "",
        stockWineName: "",
        stockWineImageUrl: "",
        stockWineLiveUrl: "",
        stockWineDescription: ""
      }
    }
  },
  methods: {
    query() {
      let url = 'api/stock-displays/';
      if (this.productCode) {
        url += `?productCode=${this.productCode}`;
      }
      axios.get(url)
          .then(response => {
            this.tableData = response.data;
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '获取数据失败'
            });
          });
    },
    confirmDeleteProduct5(stockWineId) {
      MessageBox.confirm('确定要删除此条摄像头地址信息吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteStockDisplay(stockWineId);
      }).catch(() => {
        Message.info('取消操作');
      });
    },
    deleteStockDisplay(stockWineId) {
      axios.delete(`api/stock-displays/${stockWineId}`)
          .then(() => {
            this.tableData = this.tableData.filter(item => item.stockWineId !== stockWineId);
            this.$message({
              type: 'success',
              message: '摄像头地址删除成功!'
            });
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '摄像头地址删除失败'
            });
          });
    },
    addStockDisplay() {
      axios
          .post("api/stock-displays/", useDataFormData(this.newStockDisplay, this.fileList), {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(response => {
            this.$message({
              type: 'success',
              message: '摄像头信息添加成功!'
            });
            this.openNewStockDisplayDialog = false;
            // 可能需要更新列表
            this.query(); // 重新加载所有数据
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '添加失败'
            });
          });
    },
    exportFileList(fileList) {
      this.fileList = fileList;
    },
  },

  created() {
    this.query();
  }
}
</script>

<style scoped>

</style>
