<template>

  <el-container style="height: 100vh;">

    <!-- 左侧边栏 -->
    <el-aside width="200px" height="100%">
      <el-menu default-active="1" @select="handleSelect" class="el-menu-vertical-demo">
        <el-menu-item index="/product-info">产品信息</el-menu-item>
<!--        <el-menu-item index="/product-gallery">产品轮换图</el-menu-item>-->
        <el-menu-item index="/customer-qrcode">客服二维码信息</el-menu-item>
        <el-menu-item index="/camera">摄像头地址</el-menu-item>
<!--        <el-menu-item index="/merchant-address">商家地址信息</el-menu-item>-->
        <el-menu-item index="/product-info2">生成二维码</el-menu-item>
        <el-menu-item index="/q-recodes">二维码信息</el-menu-item>
      </el-menu>
    </el-aside>


    <el-main height="100%">
      <el-header height="60px"  style="text-align: center; line-height: 20px;">
        <h2>酒溯源后台系统</h2>
        <el-button type="info" @click="logout" style="float: right;">退出</el-button>
      </el-header>

      <router-view></router-view>
    </el-main>

  </el-container>
</template>

<script>
export default {
  methods: {
    handleSelect(key, keyPath) {
      this.$router.push(key);
    }
  }
};
</script>


<script>


export default {
  data() {
    return {
      user: {
        username: "",
        uid: null,
      },
    };
  },
  methods: {
    handleSelect(route) {
      this.$router.push(route);
    },
    logout(){
      // 移除本地用户登录信息
      sessionStorage.removeItem('userInfo');
      // 跳转页面到登录页
      this.$router.push('/login');
    }
  },


  mounted() {
    if (sessionStorage.getItem('userInfo')) {
      // 将用户信息存储到sessionStorage中
      this.user = JSON.parse(sessionStorage.getItem('userInfo'));
    }

  },
};
</script>

<style scoped>



</style>
