<template>
  <div class="products" style="padding: 20px;">
    <!-- 搜索框 -->
    <el-form :inline="true" class="demo-form-inline">


    </el-form>
    <!-- 数据表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="productId" label="产品ID" ></el-table-column>
      <el-table-column prop="productCode" label="产品编号" ></el-table-column>
      <el-table-column prop="productName" label="产品名称" ></el-table-column>
      <el-table-column prop="nameIntroduction" label="产品介绍"></el-table-column>
      <el-table-column prop="baseLiquorYear" label="基酒年份"></el-table-column>
      <el-table-column prop="manufacturer" label="生产厂家"></el-table-column>
      <el-table-column prop="alcoholContent" label="酒精度（°）"></el-table-column>
      <el-table-column prop="netContent" label="净含量（ml）"></el-table-column>
      <el-table-column prop="productionBatch" label="生产批次（年份）"></el-table-column>
      <el-table-column prop="productionQuantity" label="生产数量（瓶）"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间" ></el-table-column>
      <el-table-column label="生成二维码">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="confirmGenerateQRCode(scope.row)">生成二维码</el-button>
        </template>
      </el-table-column>

    </el-table>



  </div>
</template>





<script>
import axios from 'axios';
import {Message, MessageBox} from "element-ui";

export default {
  name: "ProductInfo",
  data() {
    return {
      productName: '',
      tableData: [],
      openNewProductDialog: false,
      newProduct: {
        productCode: "",
        productName: "",
        nameIntroduction: "",
        baseLiquorYear: "",
        productionBatch: "",
        productionQuantity: "",
        manufacturer: "",
        alcoholContent: "",
        netContent: ""
      }
    }
  },
  methods: {
    query() {
      axios.get('api/products/')
          .then(response => {
            this.tableData = response.data;
          })
          .catch(error => {
            console.error(error);
          })
    },


    confirmGenerateQRCode(ProductInfo) {
      MessageBox.confirm('确认要生成二维码吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.generateQRCode(ProductInfo);
      }).catch(() => {
        Message.info('取消操作');
      });
    },
    generateQRCode(ProductInfo) {
      axios({
        method: 'post',
        url: '/api/generate_qrcode/',
        data: ProductInfo,
        responseType: 'blob', // 重要：确保以blob形式接收文件数据
      })
          .then(response => {
            // 处理响应
            console.log('二维码生成成功：', response.data);
            Message.success('二维码生成成功!');

            // 从响应头中获取文件名
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'default_filename.zip';  // 提供一个默认文件名
            if (contentDisposition) {
              const filenameRegex = /filename\*?=[^;=\n]*'[^']*'([^;\n]*)|filename=[^;=\n]*((['"]).*?\3|[^;\n]*)/;
              const matches = filenameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                filename = decodeURIComponent(matches[1]); // 解码并去除可能的引号
              } else if (matches != null && matches[2]) {
                filename = matches[2].replace(/['"]/g, '');
              }
            }

            // 触发文件下载
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/zip'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // 设置解析出的文件名
            document.body.appendChild(link);
            link.click();  // 模拟点击自动下载
            window.URL.revokeObjectURL(url);  // 清理释放URL对象
            link.remove();  // 从文档中移除链接元素
          })
          .catch(error => {
            // 处理错误
            console.error('生成二维码失败：', error);
            Message.error('生成二维码失败，请重试！');
          });
    }




  },
  created() {
    this.query();
  }
}
</script>




<style scoped>

</style>