<template>
  <div>
    <el-upload
      ref="upload"
      action="#"
      :multiple="multiple"
      accept="image/*"
      list-type="picture-card"
      :auto-upload="false"
      :on-remove="handleRemove"
      :on-preview="handlePictureCardPreview"
      :on-change="handleFileChange"
      :file-list="fileList"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: {multiple:{default:true}},
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
    };
  },
  methods: {
    handleRemove(file) {
      this.fileList = this.fileList.filter((f) => f.uid !== file.uid);
      console.log("File removed:", file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
    exportFileList() {
      // 触发自定义事件，将文件列表传递给父组件
      this.$emit("exportFileList", this.fileList);
    },
  },
  watch: {
    fileList: {
      handler() {
        this.exportFileList();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>