<template>
  <h2>66666666</h2>
</template>

<script>
export default {
  name: "UserInfo"
}
</script>

<style scoped>

</style>