<template>
  <div class="qrcodes" style="padding: 20px;">
    <!-- 搜索框 -->
    <el-form :inline="true" class="demo-form-inline">

      <el-form-item>
        <el-button type="primary" @click="openNewCustomerServiceDialog = true">新增二维码</el-button>
      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
<!--      <el-table-column prop="cusId" label="客服ID" ></el-table-column>-->
      <el-table-column prop="productCode" label="所属产品编号" ></el-table-column>
      <el-table-column prop="cusType" label="类型" ></el-table-column>
      <el-table-column prop="cusCategory" label="分类" ></el-table-column>
      <el-table-column prop="cusUrl" label="二维码" >
        <template v-slot:default="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.cusUrl" fit="cover"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="cusDescription" label="二维码名称" ></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template v-slot:default="scope">
          <el-button size="mini" type="danger" @click="confirmDeleteProduct3(scope.row.cusId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        :visible.sync="openNewCustomerServiceDialog"
        title="新增客服二维码"
        width="50%">
      <el-form  :inline="true" :model="newCustomerService">
        <el-form-item label="产品编号">
          <el-input v-model="newCustomerService.productCode" placeholder="请输入产品编号(必须已存在的产品编号)"></el-input>
        </el-form-item>
        <el-form-item label="二维码类型（可不填）">
          <el-input v-model="newCustomerService.cusType" placeholder="请输入二维码类型"></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select v-model="newCustomerService.cusCategory" placeholder="请选择分类">
            <el-option label="商城" value="商城"></el-option>
            <el-option label="客服" value="客服"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二维码">
          <FileUpload :multiple="false" @exportFileList="exportFileList" />
        </el-form-item>
        <el-form-item label="二维码名称">
          <el-input
              type="textarea"
              v-model="newCustomerService.cusDescription"
              placeholder="请输入二维码名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openNewCustomerServiceDialog = false">取消</el-button>
        <el-button type="primary" @click="addCustomerService">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import {Message, MessageBox} from "element-ui";
import FileUpload from "@/views/components/FileUpload.vue";
import {useDataFormData} from "@/utils/useDataFormData";

export default {
  name: "CustomerServiceQR",
  components: {FileUpload},
  data() {
    return {
      productCode: '',
      tableData: [],
      openNewCustomerServiceDialog: false,
      newCustomerService: {
        productCode: "",
        cusType: "",
        cusCategory: "",
        cusUrl: "",
        cusDescription: ""
      }
    }
  },
  methods: {
    query() {
      let url = 'api/customer-service/';
      if (this.productCode) {
        url += `?productCode=${this.productCode}`;
      }
      axios.get(url)
          .then(response => {
            this.tableData = response.data;
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '获取数据失败'
            });
          });
    },
    confirmDeleteProduct3(cusId) {
      MessageBox.confirm('确定要删除此条二维码信息吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteQRCode(cusId);
      }).catch(() => {
        Message.info('取消操作');
      });
    },

    deleteQRCode(cusId) {
      axios.delete(`api/customer-service/${cusId}`)
          .then(() => {
            this.tableData = this.tableData.filter(qr => qr.cusId !== cusId);
            this.$message({
              type: 'success',
              message: '二维码删除成功!'
            });
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '二维码删除失败'
            });
          });
    },
      addCustomerService() {
      axios
          .post("api/customer-service/", useDataFormData(this.newCustomerService, this.fileList), {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(response => {
            this.$message({
              type: 'success',
              message: '客服二维码添加成功!'
            });
            this.openNewCustomerServiceDialog = false;
            // 适当的逻辑来更新列表显示新添加的数据
            this.query(); // 重新加载所有数据
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '添加失败'
            });
          });
    },
    exportFileList(fileList) {
      this.fileList = fileList;
    },
  },

  created() {
    this.query();
  }
}
</script>

<style scoped>

</style>
