import { render, staticRenderFns } from "./ProductInfo2.vue?vue&type=template&id=7a75bd5c&scoped=true"
import script from "./ProductInfo2.vue?vue&type=script&lang=js"
export * from "./ProductInfo2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a75bd5c",
  null
  
)

export default component.exports