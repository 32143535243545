<template>
  <div class="media-displays" style="padding: 20px">
    <!-- 搜索框 -->
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button type="primary" @click="openNewMediaDialog = true"
          >新增轮换图</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column
        prop="productCode"
        label="所属产品编号"
      ></el-table-column>
      <el-table-column prop="mediaType" label="媒体类型"></el-table-column>
      <el-table-column prop="mediaCategory" label="媒体分类"></el-table-column>
      <el-table-column prop="url" label="图片URL">
        <template v-slot:default="scope">
          <el-image
            style="width: 100px; height: 60px"
            :src="scope.row.url"
            fit="cover"
          ></el-image>
        </template>
      </el-table-column>

      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template v-slot:default="scope">
          <el-button
            size="mini"
            type="danger"
            @click="confirmDeleteProduct2(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="openNewMediaDialog"
      title="新增商品轮换图"
      width="50%"
    >
      <el-form :inline="true" :model="newMedia">
        <el-form-item label="产品编号">
          <el-input
            v-model="newMedia.productCode"
            placeholder="请输入产品编号(必须已存在的产品编号)"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <FileUpload @exportFileList="exportFileList" />
        </el-form-item>
        <el-form-item label="媒体类型（可不填）">
          <el-input
            v-model="newMedia.mediaType"
            placeholder="请输入媒体类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类（可不填）">
          <el-input
            v-model="newMedia.mediaCategory"
            placeholder="请输入图片类型分类"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述（可不填）">
          <el-input
            type="textarea"
            v-model="newMedia.description"
            placeholder="请输入描述"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openNewMediaDialog = false">取消</el-button>
        <el-button type="primary" @click="addMedia">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { Message, MessageBox } from "element-ui";
import FileUpload from "@/views/components/FileUpload.vue";
import { useDataFormData } from "@/utils/useDataFormData";

export default {
  name: "ProductGallery",
  components: { FileUpload },
  data() {
    return {
      productCode: "",
      tableData: [],
      openNewMediaDialog: false,
      newMedia: {
        productCode: "",
        mediaType: "",
        mediaCategory: "",
        url: "",
        description: "",
      },
      fileList: [],
    };
  },
  methods: {
    query() {
      let url = "api/media/";
      if (this.productCode) {
        url += `?productCode=${this.productCode}`;
      }
      axios
        .get(url)
        .then((response) => {
          this.tableData = response.data;
        })
        .catch((error) => {
          console.error(error);
          this.$message({
            type: "error",
            message: "获取数据失败",
          });
        });
    },
    confirmDeleteProduct2(id) {
      MessageBox.confirm("确定要删除此条轮换图信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteMedia(id);
        })
        .catch(() => {
          Message.info("取消操作");
        });
    },
    deleteMedia(id) {
      axios
        .delete(`api/media/${id}`)
        .then(() => {
          this.tableData = this.tableData.filter((item) => item.id !== id);
          this.$message({
            type: "success",
            message: "媒体删除成功!",
          });
        })
        .catch((error) => {
          console.error(error);
          this.$message({
            type: "error",
            message: "媒体删除失败",
          });
        });
    },
    addMedia() {
      axios
          .post("api/media/sdf", useDataFormData(this.newMedia, this.fileList), {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.$message({
              type: "success",
              message: "商品轮换图添加成功!",
            });
            this.openNewMediaDialog = false;
            this.query(); // 重新加载所有数据
          })
          .catch((error) => {
            console.error(error);
            this.$message({
              type: "error",
              message: "添加失败",
            });
          });
    },
    exportFileList(fileList) {
      this.fileList = fileList;
    },
  },
  created() {
    this.query();
  },
};
</script>

<style scoped></style>