<template>
  <div class="addresses" style="padding: 20px;">
    <!-- 搜索框 -->
    <el-form :inline="true" class="demo-form-inline">

      <el-form-item>

        <el-button type="primary" @click="openNewAddressDialog = true">新增地址</el-button>
      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <el-table :data="tableData1" stripe style="width: 100%">
<!--      <el-table-column prop="id" label="ID" width="100"></el-table-column>-->
      <el-table-column prop="productCode" label="所属产品编号" ></el-table-column>
<!--      <el-table-column prop="latitude" label="纬度" ></el-table-column>-->
<!--      <el-table-column prop="longitude" label="经度"></el-table-column>-->
      <el-table-column prop="StoreName" label="店名"></el-table-column>
      <el-table-column prop="remarks" label="详细地址"></el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template v-slot:default="scope">
          <el-button size="mini" type="danger" @click="confirmDeleteProduct4(scope.row.productCode)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        :visible.sync="openNewAddressDialog"
        title="新增厂家地址"
        width="50%">
      <el-form :inline="true" :model="newAddress">
        <el-form-item label="产品编号">
          <el-input v-model="newAddress.productCode" placeholder="请输入产品编号(必须已存在的产品编号)"></el-input>
        </el-form-item>
<!--        <el-form-item label="纬度">-->
<!--          <el-input v-model="newAddress.latitude" placeholder="请输入纬度"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="经度">-->
<!--          <el-input v-model="newAddress.longitude" placeholder="请输入经度"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="店名">
          <el-input v-model="newAddress.StoreName" placeholder="请输入店名"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="newAddress.remarks" placeholder="请输入详细地址"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openNewAddressDialog = false">取消</el-button>
        <el-button type="primary" @click="addAddress">提交</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import axios from 'axios';
import {Message, MessageBox} from "element-ui";
export default {
  name: "MerchantAddress",
  data() {
    return {
      productName: '',
      tableData: [],
      tableData1: [],
      openNewAddressDialog: false,
      newAddress: {
        productCode: "",
        latitude: "",
        longitude: "",
        StoreName: "",
        remarks: ""
      },
    }
  },
  methods: {
    query() {
      axios.get('api/manufacturerAddresses/')
          .then(response => {
            // 假设返回的数据格式为数组
            this.tableData = response.data;
            this.tableData1 = this.tableData.map(item => {
              const [StoreName, ...rest] = item.remarks.split(':');
              return {
                ...item,
                StoreName: StoreName || '', // 防止 StoreName 为空
                remarks: rest.join(':') // 合并剩下的部分为新的 remarks
              };
            });
          })
          .catch(error => {
            console.error(error);
          });
    },
    confirmDeleteProduct4(productCode) {
      MessageBox.confirm('确定要删除该商品绑定的所有商家地址信息吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteAddress(productCode);
      }).catch(() => {
        Message.info('取消操作');
      });
    },
    deleteAddress(productCode) {
      axios.delete(`api/manufacturerAddresses/${productCode}`)
          .then(() => {
            this.tableData = this.tableData.filter(address => address.productCode !== productCode);
            this.$message({
              type: 'success',
              message: '地址删除成功!'
            });
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '地址删除失败'
            });
          });
    },
    addAddress() {
      // 拼接 StoreName 和 remarks 字段
      const combinedRemarks = `${this.newAddress.StoreName}:${this.newAddress.remarks}`;
      const addressToSubmit = {
        ...this.newAddress,
        remarks: combinedRemarks
      };

      axios.post('api/manufacturerAddresses/', addressToSubmit)
          .then(response => {
            this.$message({
              type: 'success',
              message: '厂家地址添加成功!'
            });
            this.openNewAddressDialog = false;
            this.query(); // 重新加载所有数据
          })
          .catch(error => {
            console.error(error);
            this.$message({
              type: 'error',
              message: '添加失败'
            });
          });
    }
  },

  created() {
    this.query();
  }

}

</script>

<style scoped>

</style>